import useApiError from '@/core/api/useApiError'
import constants from '@/features/user/_framework/constants'
import Contact from '@/features/user/_framework/models/Contact'
import useUser2SessionContext from '@/features/user2/_framework/composables/useUser2SessionContext'
import store from '@/store'
import { ref } from '@vue/composition-api'

export default function useUser() {
  // =========================================
  // register module(s)
  // =========================================

  // =========================================
  // error handling
  // =========================================
  const { addError } = useApiError()

  // =========================================
  // user session context
  // =========================================
  const { organizationContext } = useUser2SessionContext()

  // =========================================
  // user session context
  // =========================================
  const contact = ref(new Contact())
  const contactActivity = ref({
    invoices: [],
    meetings: [],
    timeline: [],
  })

  const convertToDto = data => {
    const dto = {
      id: data ? data.id : '00000000-0000-0000-0000-000000000000',
      salutationCode: data ? data.salutationCode : '',
      prefixCode: data ? data.prefixCode : '',
      firstName: data ? data.firstName : '',
      middleName: data ? data.middleName : '',
      lastName: data ? data.lastName : '',
      suffixCode: data ? data.suffixCode : '',
      nickname: data ? data.nickname : '',
      designationCode: data ? data.designationCode : '',
      title: data ? data.title : '',
      companyName: data ? data.companyName : '',
      notes: data ? data.notes : '',
      bio: data ? data.bio : '',
      emailAddress: data ? data.emailAddress : '',
      phoneNumber: data ? data.phoneNumber : '',
      mobileNumber: data ? data.mobileNumber : '',
      faxNumber: data ? data.faxNumber : '',
      profilePictureUrl: data ? data.profilePictureUrl : '',
      profilePictureData: data ? data.profilePictureUrl : '',
      address1: data ? data.address1 : '',
      address2: data ? data.address2 : '',
      address3: data ? data.address3 : '',
      city: data ? data.city : '',
      stateProvinceCode: data ? data.stateProvinceCode : '',
      zipCode: data ? data.zipCode : '',
      countryCode: data ? data.countryCode : '',
      assistantName: data ? data.assistantName : '',
      assistantEmailAddress: data ? data.assistantEmailAddress : '',
      assistantPhoneNumber: data ? data.assistantPhoneNumber : '',
      specialties: data ? data.specialties : [],
      barAdmissions: data ? data.barAdmissions : [],
      membershipContext: {
        organizationId: data?.membershipContext?.organizationId ?? '',
        memberTypeCode: data?.membershipContext?.memberTypeCode ?? '',
        memberSubTypeCode: data?.membershipContext?.memberSubTypeCode ?? '',
        memberStatusCode: data?.membershipContext?.memberStatusCode ?? '',
        beginDate: data?.membershipContext?.beginDate ?? null,
        endDate: data?.membershipContext?.endDate ?? null,
      },
    }

    return JSON.parse(JSON.stringify(dto))
  }

  // =========================================
  // api
  // =========================================
  const createContact = async apiData => {
    try {
      const response = await store.dispatch(`${constants.USER_STORE_MODULE_NAME}/createContact`, {
        routeParameters: {
          organizationId: organizationContext.value.organizationId,
        },
        queryParameters: {},
        dto: apiData.dto,
      })

      if (response.status === 200 || response.status === 201) {
        return true
      }

      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  const updateContact = async apiData => {
    try {
      const response = await store.dispatch(`${constants.USER_STORE_MODULE_NAME}/updateContact`, {
        routeParameters: {
          organizationId: organizationContext.value.organizationId,
          contactId: apiData.contactId,
        },
        queryParameters: {},
        dto: apiData.dto,
      })

      if (response.status === 200 || response.status === 201) {
        return true
      }

      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  const uploadContactProfilePicture = async apiData => {
    try {
      const response = await store.dispatch(`${constants.USER_STORE_MODULE_NAME}/uploadContactProfilePicture`, {
        routeParameters: {
          organizationId: organizationContext.value.organizationId,
          contactId: apiData.contactId,
        },
        queryParameters: apiData.queryParameters,
        dto: apiData.dto,
      })

      if (response.status === 200 || response.status === 201) {
        return true
      }

      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  const fetchContact = async contactId => {
    try {
      const response = await store.dispatch(`${constants.USER_STORE_MODULE_NAME}/fetchContact`, {
        routeParameters: {
          contactId,
          organizationId: organizationContext.value.organizationId,
        },
        queryParameters: {},
      })

      if (response.status === 200) {
        contact.value = JSON.parse(JSON.stringify(response.data))

        return true
      }

      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  const fetchContactActivity = async contactId => {
    try {
      const response = await store.dispatch(`${constants.USER_STORE_MODULE_NAME}/fetchContactActivity`, {
        routeParameters: {
          contactId,
          organizationId: organizationContext.value.organizationId,
        },
        queryParameters: {},
      })

      if (response.status === 200) {
        contactActivity.value = JSON.parse(JSON.stringify(response.data))

        return true
      }

      addError(response.data)

      return false
    } catch (error) {
      addError(error)

      return false
    }
  }

  // =========================================
  // return
  // =========================================
  return {
    contact,
    convertToDto,
    contactActivity,

    createContact,
    updateContact,
    uploadContactProfilePicture,
    fetchContact,
    fetchContactActivity,
  }
}
