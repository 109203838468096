import { mdiChartBoxOutline } from '@mdi/js'

export default [
  {
    title: 'Report List',
    icon: mdiChartBoxOutline,
    to: 'feature-report-list',
    resource: 'Tenant',
  },
]
